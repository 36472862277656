// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-contact-success-js": () => import("./../src/pages/contact-success.js" /* webpackChunkName: "component---src-pages-contact-success-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-health-and-safety-js": () => import("./../src/pages/health-and-safety.js" /* webpackChunkName: "component---src-pages-health-and-safety-js" */),
  "component---src-pages-hedge-maintenance-js": () => import("./../src/pages/hedge-maintenance.js" /* webpackChunkName: "component---src-pages-hedge-maintenance-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-site-clearance-js": () => import("./../src/pages/site-clearance.js" /* webpackChunkName: "component---src-pages-site-clearance-js" */),
  "component---src-pages-stump-grinding-js": () => import("./../src/pages/stump-grinding.js" /* webpackChunkName: "component---src-pages-stump-grinding-js" */),
  "component---src-pages-tree-surgery-js": () => import("./../src/pages/tree-surgery.js" /* webpackChunkName: "component---src-pages-tree-surgery-js" */),
  "component---src-pages-woodland-maintenance-js": () => import("./../src/pages/woodland-maintenance.js" /* webpackChunkName: "component---src-pages-woodland-maintenance-js" */)
}

